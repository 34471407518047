exports.printMsg = function () {
    "use strict";
    console.log("This is a message from the demo package");
};


exports.readTextFile = function readTextFile(file, callback) {
    "use strict";
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function () {
        if (rawFile.readyState === 4 && rawFile.status === "200") {
            callback(rawFile.responseText);
        }
            
    };
    rawFile.send(null);
};